import { Layout } from 'components/layout'
import { PAGES } from 'constants/routes'
import Link from 'next/link'
import { withAuthUser } from 'next-firebase-auth'
import styled from 'styled-components'
import { urlWithSiteOrigin } from 'utils/url-with-site-origin'

const Wrapper = styled.div`
  overflow: auto;
  max-width: 62.5rem;
  margin: 0 auto;
  padding: 5rem 1.25rem 6.25rem 7.5rem;
  color: ${({ theme }) => theme.palette.error.errorPageColor};

  h2 {
    @media (max-width: 64rem) {
      font-size: 1.25rem;
    }
  }

  h3 {
    @media (max-width: 64rem) {
      font-size: 1rem;
    }
  }

  li {
    list-style: none;
    display: flex;
  }

  @media (max-width: 64rem) {
    padding-left: 1.25rem;
    max-width: 37.5rem;
  }
`

const Header = styled.h1`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.heading1}
  font-size: 5.625rem;

  & > * + * {
    margin-left: 2rem;
  }

  @media (max-width: 64rem) {
    font-size: 4rem;
    & > * + * {
      margin-left: 1rem;
    }
  }
`

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.link.main};
  ${({ theme }) => theme.typography.heading2}

  & > * + * {
    margin-left: 0.5rem;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.link.main};
    text-decoration: underline;
  }

  @media (max-width: 64rem) {
    font-size: 1rem;
  }
`

const Divider = styled.div`
  margin-bottom: 1.5rem;
`

const DividerSmall = styled.div`
  margin-bottom: 0.5rem;
`

const ErrorIcon = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 640 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z"></path>
  </svg>
)

const LessonIcon = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M224 96l16-32 32-16-32-16-16-32-16 32-32 16 32 16 16 32zM80 160l26.66-53.33L160 80l-53.34-26.67L80 0 53.34 53.33 0 80l53.34 26.67L80 160zm352 128l-26.66 53.33L352 368l53.34 26.67L432 448l26.66-53.33L512 368l-53.34-26.67L432 288zm70.62-193.77L417.77 9.38C411.53 3.12 403.34 0 395.15 0c-8.19 0-16.38 3.12-22.63 9.38L9.38 372.52c-12.5 12.5-12.5 32.76 0 45.25l84.85 84.85c6.25 6.25 14.44 9.37 22.62 9.37 8.19 0 16.38-3.12 22.63-9.37l363.14-363.15c12.5-12.48 12.5-32.75 0-45.24zM359.45 203.46l-50.91-50.91 86.6-86.6 50.91 50.91-86.6 86.6z"></path>
  </svg>
)

const StatsIcon = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 544 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M527.79 288H290.5l158.03 158.03c6.04 6.04 15.98 6.53 22.19.68 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-15.83-64.8C503.72 103.74 408.26 8.28 288.8.04 279.68-.59 272 7.1 272 16.24V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM224 288V50.71c0-9.55-8.39-17.4-17.84-16.06C86.99 51.49-4.1 155.6.14 280.37 4.5 408.51 114.83 513.59 243.03 511.98c50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288z"></path>
  </svg>
)

const QuestionIcon = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 384 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"></path>
  </svg>
)

function NotFound() {
  return (
    <Layout
      seoProps={{
        canonicalUrl: urlWithSiteOrigin('/404/'),
        pageUrl: urlWithSiteOrigin('/404/'),
        noIndex: true,
      }}
    >
      <Wrapper>
        <Header>
          {ErrorIcon} <span>Oops!</span>
        </Header>
        <Divider />
        <h2>We can&apos;t seem to find the page you are looking for.</h2>
        <DividerSmall />
        <p>Error code: 404</p>
        <DividerSmall />
        <h3>Probably you would be interested in the following links instead:</h3>
        <Divider />
        <ul>
          <li>
            <Link href={PAGES['/lessons/']} passHref legacyBehavior prefetch={false}>
              <StyledLink>
                {LessonIcon} <span>Lessons</span>
              </StyledLink>
            </Link>
          </li>
          <DividerSmall />
          <li>
            <Link href={PAGES['/stats/']} passHref legacyBehavior prefetch={false}>
              <StyledLink>
                {StatsIcon} <span>Stats</span>
              </StyledLink>
            </Link>
          </li>
          <DividerSmall />
          <li>
            <Link href={PAGES['/help/']} passHref legacyBehavior prefetch={false}>
              <StyledLink>
                {QuestionIcon} <span>Help &amp; support</span>
              </StyledLink>
            </Link>
          </li>
        </ul>
      </Wrapper>
    </Layout>
  )
}

export default withAuthUser()(NotFound)
